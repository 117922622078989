import React, { Component } from 'react';
import './App.css';


class Archives extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      music : document.getElementById('player__object')
    };
    this.playAudio = this.playAudio.bind(this);
  }

  componentDidMount() {
    fetch("https://evolveva.com/archives/radio/files.json")
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  
  playAudio(index) {
    console.log(this.state.items[index]);
    console.log(this);
    this.state.music.src = 'http://evolveva.com/archives/radio/' + this.state.items[index].url;
    this.state.music.play();
  }
  
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div id="load">
          <div>G</div>
          <div>N</div>
          <div>I</div>
          <div>D</div>
          <div>A</div>
          <div>O</div>
          <div>L</div>
        </div>
      )
    } else {
      return (
        <div className="l-wrapper">
          <div className="l-gutter">
            {items.map((item, index) => (
              <div className="player__files" key={index}>
                <div className="l-wrapper">
                  <div className="player__action">
                    <button onClick={this.playAudio.bind(this, index)} target="_blank" className="player__files-link">Play</button>
                  </div>
                  <div className="player__file">
                    <div className="player__name">{item.artist}</div>
                    <div className="player__date">{item.date}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }
}


export default Archives;